import styled from '@emotion/styled'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { WebLink } from '@news-mono/web-common'
import { breakpoint, calcRem } from '../../../../__styling'
import { perthNowNGNTextMixin } from '../../../../mixins'

export const StyledPNErrorPageWrapper = styled('div')({
    marginTop: metricsPN.spacing['XL'],
    [breakpoint('lg')]: {
        marginTop: metricsPN.layout.section.desktop,
    },
})

export const StyledErrorHeading = styled('h1')({
    margin: calcRem(0, 0, metricsPN.spacing['L']),
    ...perthNowNGNTextMixin.title['L'].bold,
    [breakpoint('lg')]: {
        ...perthNowNGNTextMixin.title['XL'].bold,
        margin: calcRem(0, 0, metricsPN.spacing['XL']),
    },
})
export const StyledErrorCode = styled('h2')({
    ...perthNowNGNTextMixin.title['XS'].semibold,
    lineHeight: calcRem(32),
    margin: calcRem(0, 0, metricsPN.spacing['XS']),

    [breakpoint('lg')]: {
        ...perthNowNGNTextMixin.title['S'].semibold,
    },
})

export const StyledErrorMessage = styled('p')({
    ...perthNowNGNTextMixin.body['L'].regular,
    margin: calcRem(0, 0, metricsPN.spacing['L']),
    [breakpoint('lg')]: {
        margin: calcRem(0, 0, metricsPN.spacing['XL']),
    },
})
export const StyledButton = styled(WebLink)({
    display: 'inline-block',
    color: colorsPN.text.inverse,
    backgroundColor: colorsPN.fill.brand.strong,
    padding: calcRem(metricsPN.spacing['S'], metricsPN.spacing['L']),
    textDecoration: 'none',
    borderRadius: `${metricsPN.radius['L']}px`,
    ...perthNowNGNTextMixin.label.L.semibold,
    margin: calcRem(0, 0, metricsPN.spacing['XL']),

    '&:hover': {
        backgroundImage: `linear-gradient(${colorsPN.fill.hover.default} 0 0)`,
    },

    '&:active': {
        backgroundImage: `linear-gradient(${colorsPN.fill.pressed.default} 0 0)`,
    },
})
