import React from 'react'
import {
    StyledErrorHeading,
    StyledErrorMessage,
    StyledErrorCode,
    StyledButton,
} from './PNErrorPage.styled'

export interface PNErrorPageProps {
    title: string
    errorCode: number
    errorMsg?: string
}

export const PNErrorPage: React.FC<PNErrorPageProps> = ({
    title,
    errorCode,
    errorMsg,
}) => {
    return (
        <>
            <StyledErrorHeading>{title}</StyledErrorHeading>
            <StyledErrorCode>{errorCode} Error</StyledErrorCode>
            {errorMsg && <StyledErrorMessage>{errorMsg}</StyledErrorMessage>}
            <StyledButton to={'/'}>Back to homepage</StyledButton>
        </>
    )
}
