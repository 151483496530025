import { PerthNowSection } from '@news-mono/component-library'
import {
    BaseClientConfig,
    ErrorResolution,
    GetRouteAdTargeting,
    noIndexMeta,
    RouteResolution,
    StaticPageRoute,
} from '@news-mono/web-common'
import { PerthNowSections } from '@west-australian-newspapers/publication-types'
import { layout } from '../App.routing'
import { PNErrorPageProps } from 'libs/component-library/src/nextgen-news/perthnow/errors/PNErrorPage/PNErrorPage'

interface GetPNErrorPageRouteProps {
    getAdTargeting: GetRouteAdTargeting
    resolution: RouteResolution
    errorProps: PNErrorPageProps
}

export const getPNErrorPageRoute = ({
    getAdTargeting,
    resolution,
    errorProps,
}: GetPNErrorPageRouteProps): ReturnType<StaticPageRoute<PerthNowSection>> => {
    const section = ((resolution as ErrorResolution).section ||
        PerthNowSections.default) as PerthNowSection

    return {
        kind: 'page',
        heading: errorProps.title,
        pageMeta: {
            meta: [noIndexMeta],
        },
        // used by `/server/middleware/optimised-ssr` to determine redirect status
        pageType: 'error',
        hideHeading: true,
        adTargeting: getAdTargeting('error', section, 'page-not-found'),
        section,
        compositions: [
            layout.composition({
                type: 'box',
                props: { hasBackgroundFill: false },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'pn-error-page',
                            props: {
                                title: errorProps.title,
                                errorCode: errorProps.errorCode,
                                errorMsg: errorProps.errorMsg,
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
