import { createRegisterableComponent, RouteStatus } from '@news-mono/web-common'
import React from 'react'
import { PNErrorPage, PNErrorPageProps } from './PNErrorPage'
import { StyledPNErrorPageWrapper } from './PNErrorPage.styled'

export const PNErrorPageRegistration = createRegisterableComponent(
    'pn-error-page',
    (props: PNErrorPageProps) => (
        <RouteStatus statusCode={props.errorCode}>
            <StyledPNErrorPageWrapper>
                <PNErrorPage {...props} />
            </StyledPNErrorPageWrapper>
        </RouteStatus>
    ),
)
