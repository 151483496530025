import { PerthNowSection } from '@news-mono/component-library'
import { pageIsUnavailable, StaticRoutes } from '@news-mono/web-common'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { cookieSettings } from './cookie-consent'
import { communityNews } from './custom/community-news'
import { customTopicRoutes } from './custom/custom-topic-routes'
import { haveYouHeard } from './custom/get-have-you-heard-route'
import { localNews } from './local-news'
import { getEmailPreferenceRouteInfo } from './email-preference'
import { ePaper, ePaperSections } from './ePaper'
import { getPerthNowPageIsUnavailableRoute } from './errors'
import { homepage } from './home'
import { horoscopes } from './horoscopes'
import { shopNow } from './shopNow'
import { entertainment } from './entertainment'
import { sportRoutes } from './sport'
import { rssPage } from './static/rss'
import { staticContentRoutes } from './static/static-content-routes'
import { getTopicPageRouteInfo } from './topic/get-topic-page-route-info'
import { news } from './news'
import { medalTally } from './olympics/medal-tally'
import {
    perthNowAccountDeletedRoute,
    perthNowEmailVerifiedRoute,
    perthNowManageAccountRoute,
} from './pn-warden'
import { appDownload } from './app-download'
import { getPNErrorPageRoute } from '../routes/ngn-errors'

export const staticRoutes: StaticRoutes<PerthNowSection> = {
    ...staticContentRoutes,
    ...customTopicRoutes,
    ...homepage,
    ...horoscopes,
    ...sportRoutes,
    ...shopNow,
    ...haveYouHeard,
    ...cookieSettings,
    ...communityNews,
    ...localNews,
    ...ePaper,
    ...ePaperSections,
    ...entertainment,
    ...news,
    ...medalTally,
    ...appDownload,
    '/news/breaking-news': ({ getAdTargeting }) =>
        getTopicPageRouteInfo({
            topic: {
                // Breaking news is just news, but it's modelled like a secondary topic
                // we add a News parent to BreakingNews here for ad targeting
                id: 'news',
                title: 'Breaking News',
                parent: {
                    id: 'news',
                    title: 'News',
                    metadata: {},
                    seoTitle: '',
                },
                metadata: {},
                seoTitle: '',
            },
            timestamp: 'list-style',
            topicSource: 'AAP',
            section: 'news',
            getAdTargeting,
        }),
    '/business/breaking-news': ({ getAdTargeting }) =>
        getTopicPageRouteInfo({
            topic: {
                id: 'business',
                parent: {
                    id: 'business',
                    title: 'Business',
                    metadata: {},
                    seoTitle: '',
                },
                title: 'Business Breaking News',
                metadata: {},
                seoTitle: '',
            },
            timestamp: 'list-style',
            topicSource: 'AAP',
            section: 'business',
            getAdTargeting,
        }),
    ...rssPage,
    ...getEmailPreferenceRouteInfo,
    [`${pageIsUnavailable}`]: (props) => {
        const { store } = props
        const isNGNEnabled = isFeatureEnabled(
            toFeatureState(store.getState().toggles),
            'perthnow-ngn-redesign-master',
        )

        if (!isNGNEnabled) {
            return getPerthNowPageIsUnavailableRoute(props)
        }
        const errorProps = {
            title: 'Oops! Page is not available.',
            errorCode: 451,
            errorMsg:
                "Sorry, but the page you've requested is unavailable in your region.",
        }
        return getPNErrorPageRoute({ ...props, errorProps })
    },
    // PerthNow Warden
    ...perthNowEmailVerifiedRoute,
    ...perthNowManageAccountRoute,
    ...perthNowAccountDeletedRoute,
}
